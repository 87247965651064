import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { transparentize } from "polished";
import Autosuggest from "react-autosuggest";
import SearchIcon from "@material-ui/icons/Search";
import { Box, Text, theme } from "@thinkingmachines/asimov";
import api from "../api";
import { UPDATE_SEARCH, UPDATE_DATASETS } from "../store";

const Searchbar = styled(({ className }) => {
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();
  const value = useSelector((state) => state.search.searchValue);
  const search = useSelector((state) => state.search);
  const inputRef = useRef();
  const onSuggestionsClearRequested = () => setSuggestions([]);
  const onSuggestionsFetchRequested = ({ value }) => {
    api
      .get(`/dataset/search`, {
        params: {
          q: value,
          limit: 5,
          geographies: search.geographyFilter && search.geographyFilter.value,
          categories: search.categoryFilter && search.categoryFilter.value,
          show_unloadable: search.showUnloadable,
        },
      })
      .then(({ data }) => setSuggestions(data));
  };
  const renderSuggestion = (suggestion) => (
    <Box className="suggestion">
      <Text fontWeight="bold" fontSize="16px">
        {suggestion.title}{" "}
      </Text>
      <Text fontSize="14px" color="gray.2">
        {suggestion.keywords.join(", ")}
      </Text>
    </Box>
  );
  const getSuggestionValue = (suggestion) => suggestion.title;
  const selectSuggestion = (id) => {
    api
      .get(`/dataset/search`, {
        params: {
          q: id,
          geographies: search.geographyFilter && search.geographyFilter.value,
          categories: search.categoryFilter && search.categoryFilter.value,
          show_unloadable: search.showUnloadable,
        },
      })
      .then(({ data }) => dispatch({ type: UPDATE_DATASETS, datasets: data }));
  };
  // Special function when enter is pressed without anything on the suggestion is
  // highlight should trigger search
  const onKeyDown = (e) => {
    if (
      e.keyCode === 13 &&
      (e.target.parentElement.children[1].children.length === 0 ||
        [].some.call(
          e.target.parentElement.children[1].children[0].children,
          (child) => !child.className.includes("highlighted")
        ))
    ) {
      selectSuggestion(value);
    }
  };
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, [inputRef]);
  const renderInputComponent = (inputProps) => {
    return (
      <Box className="inputContainer">
        <SearchIcon className="icon" />
        <input {...inputProps} ref={inputRef} />
      </Box>
    );
  };

  return (
    <div className={className} style={{ width: "100%" }}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        getSuggestionValue={getSuggestionValue}
        inputProps={{
          ref: inputRef,
          placeholder: "Search for datasets",
          value: value,
          onChange: (e, { newValue }) =>
            dispatch({
              type: UPDATE_SEARCH,
              search: { searchValue: newValue },
            }),
          onKeyDown: (e) => onKeyDown(e),
        }}
        list
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(e, { suggestion }) =>
          selectSuggestion(suggestion.id)
        }
      />
    </div>
  );
})`
  width: 45%;

  .suggestion > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .inputContainer {
    position: relative;
    width: 100%;
  }
  .icon {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 24px;
    height: 24px;
  }

  .react-autosuggest__container {
    position: relative;
    width: 95%;
  }

  .react-autosuggest__input {
    background-color: rgba(0, 0, 0, 0.03);
    height: 30px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 5px 20px 5px 40px;
  }

  .react-autosuggest__input--focused {
    outline: none;
    border: solid 1px #858384;
    box-shadow: 0 0 1px 1px #858384;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  .react-autosuggest__suggestions-container--open {
    margin-top: 4px;
    display: block;
    position: absolute;
    width: calc(100% - 2px);
    border-color: #d6d3d5;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    background-color: white;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-radius: 2px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${transparentize(0.75, theme.colors.accent[0])};
  }

  .react-autosuggest__suggestion-match {
    color: red;
    font-weight: bold;
  }
`;
export default Searchbar;
