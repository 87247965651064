import React from "react";

import Modal from "react-modal";
import { Box, Text, H5, Paragraph, Button } from "@thinkingmachines/asimov";

import { useDispatch, useSelector } from "react-redux";
import api from "../api";

import { FlyToInterpolator } from "deck.gl";
import { WebMercatorViewport } from "@math.gl/web-mercator";
import bbox from "@turf/bbox";
import axios from "axios";
import * as telemetry from "../telemetry";
import {
  UPDATE_ISWARNING,
  UPDATE_MAPLAYERS,
  UPDATE_DATASET_INFO,
  UPDATE_VIEWPORT,
  UPDATE_MAP_LOADING_PROGRESS,
  UPDATE_DATASET_INFO_MODAL,
  UPDATE_LEGENDS,
  UPDATE_LOADED_DATASETS_COUNT,
  UPDATE_MAP_HAS_BEEN_LOADED,
} from "../store";

import { X } from "react-feather";

const DatasetInfo = () => {
  const dispatch = useDispatch();
  const isDatasetInfoOpen = useSelector((state) => state.isDatasetInfoOpen);
  const viewport = useSelector((state) => state.viewport);
  const mapLayers = useSelector((state) => state.mapLayers);
  const data = useSelector((state) => state.datasetInfo);
  const isLoaded = data ? mapLayers.hasOwnProperty(data.id) : null;
  const legends = useSelector((state) => state.legends);
  const loadedDatasetsCount = useSelector((state) => state.loadedDatasetsCount);
  const mapHasBeenLoaded = useSelector((state) => state.mapHasBeenLoaded);

  const loadData = ({ datasetInfo, isLoaded }) => {
    const { id, categories, geographies } = datasetInfo;
    // Close warning when loading
    // Remove data if it is already loaded. Else remove
    if (isLoaded) {
      const { [id]: omittedLayers, ...newGeoJson } = mapLayers;
      const { [id]: omittedLegends, ...newLegends } = legends;
      dispatch({ type: UPDATE_MAPLAYERS, mapLayers: newGeoJson });
      dispatch({ type: UPDATE_LEGENDS, legends: newLegends });

      // Subtract from loaded datasets count
      let count = loadedDatasetsCount;
      count--;
      dispatch({
        type: UPDATE_LOADED_DATASETS_COUNT,
        loadedDatasetsCount: count,
      });
    } else {
      telemetry.logLoadData({
        datasetId: id,
        loadedData: Object.keys(mapLayers),
        categories,
        geographies,
      });
      dispatch({
        type: UPDATE_ISWARNING,
        isWarning: false,
        isMapLoading: true,
      });
      api
        .get(`/dataset/download/${id}`)
        .then(({ data }) =>
          // Remove credentials to fix CORS
          //https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
          axios
            .get(data.redirect_url, {
              withCredentials: false,
              onDownloadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                dispatch({
                  type: UPDATE_MAP_LOADING_PROGRESS,
                  mapLoadingProgress: percentCompleted,
                });
              },
            })
            .then(({ data }) => {
              dispatch({
                type: UPDATE_MAPLAYERS,
                mapLayers: {
                  ...mapLayers,
                  [id]: { data, datasetInfo, isVisible: true },
                },
                isMapLoading: false,
              });

              let inviteCount = mapHasBeenLoaded;
              inviteCount++;

              dispatch({
                type: UPDATE_MAP_HAS_BEEN_LOADED,
                mapHasBeenLoaded: inviteCount,
              });

              // Add to loaded datasets count
              let count = loadedDatasetsCount;
              count++;
              dispatch({
                type: UPDATE_LOADED_DATASETS_COUNT,
                loadedDatasetsCount: count,
              });

              const box = bbox(data);
              const { longitude, latitude, zoom } = new WebMercatorViewport(
                viewport
              ).fitBounds([box.slice(0, 2), box.slice(2, 4)], { padding: 20 });
              dispatch({
                type: UPDATE_VIEWPORT,
                viewport: {
                  ...viewport,
                  longitude,
                  latitude,
                  zoom: zoom,
                  transitionDuration: 1000,
                  transitionInterpolator: new FlyToInterpolator(),
                },
              });
            })
        )
        .catch((e) => {
          dispatch({
            type: UPDATE_ISWARNING,
            isWarning: true,
            isMapLoading: false,
          });
        });
    }
  };

  return (
    <Modal
      parentSelector={() => document.querySelector("#side")}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      style={{
        overlay: {
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 8000000000,
        },
        content: {
          padding: "0px",
          margin: "auto auto",
          inset: null,
          width: "40vw",
          position: "relative",
          marginTop: "25vh",
        },
      }}
      isOpen={Boolean(data && isDatasetInfoOpen)}
      onRequestClose={() =>
        dispatch({ type: UPDATE_DATASET_INFO, datasetInfo: null })
      }
    >
      {data ? (
        <Box flexDirection="column" height="100%" pt={5} pb={5}>
          <Box pl={5} pr={5} flexBasis="row" alignItems="flex-end">
            <Box
              color="#ACAAAB"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch({
                  type: UPDATE_DATASET_INFO_MODAL,
                  isDatasetInfoOpen: false,
                });
              }}
            >
              <X></X>
            </Box>
          </Box>

          <Box mt={3} overflowY="scroll" flexGrow="1">
            <Box pl={5} pr={5} flexDirection="row">
              <H5>{data.title}</H5>
            </Box>

            <Box pl={5} pr={5} mt={2}>
              <Paragraph fontSize="14px">{data.description}</Paragraph>
            </Box>

            {/* Hidden for now */}

            {/* <Box pl={5} pr={5} flexDirection="row" alignItems="center" mt={5}>
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                width="36px"
                height="36px"
                borderRadius="100px"
                backgroundColor="rgba(82, 103, 180, 0.1)"
              >
                <Eye color="#5268B4"></Eye>
              </Box>
              <H5 ml={3} fontSize="16px">
                INSIGHTS
              </H5>
            </Box>

            <Box pl={5} pr={5} mt={2}>
              <Paragraph fontSize="14px">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Paragraph>
            </Box>

            <Box
              pl={5}
              pr={5}
              pt={3}
              pb={5}
              flexDirection="column"
              backgroundColor="rgba(82, 103, 180, 0.1)"
              mt={5}
            >
              <H5 fontSize="16px">Data is more insightful when paired with:</H5>
              <Box flexDirection="column">
                <Link mt={2} color="sanmarino" fontSize="14px">
                  2020 Philippines Facebook Marketing API Data for Device,
                  Network, and Travel
                </Link>
                <Link mt={2} color="sanmarino" fontSize="14px">
                  2017 Philippines GeoAI Wealth Estimates
                </Link>
                <Link mt={2} color="sanmarino" fontSize="14px">
                  2009, 2012, and 2015 Small Area poverty Estimates in the
                  Philippines
                </Link>
              </Box>
            </Box> */}

            <Box mt={3} pl={5} pr={5}>
              <H5 fontSize="16px">Keywords</H5>
            </Box>

            <Box mt={2} pl={5} pr={5} flexDirection="row" flexWrap="wrap">
              {data.keywords.map((keyword, id) => (
                <Box key={id} mr="3" my="1">
                  <Text fontWeight="bold" color="sanmarino" fontSize="14px">
                    {keyword}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>

          <Box mt={10} pl={5} pr={5} flexDirection="row" alignItems="center">
            {data.can_load ? (
              <Button
                variant={isLoaded ? "secondary" : "primary"}
                buttonColor="accent.3"
                onClick={(e) =>
                  e.stopPropagation() ||
                  loadData({
                    datasetInfo: data,
                    isLoaded,
                  })
                }
              >
                {isLoaded ? "REMOVE FROM MAP" : "ADD TO MAP"}
              </Button>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </Modal>
  );
};
export default DatasetInfo;
