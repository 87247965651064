import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { Box, H5, Paragraph, Button, Input } from "@thinkingmachines/asimov";

import { UPDATE_IS_GET_IN_TOUCH } from "../store";
import api from "../api";
import * as telemetry from "../telemetry";
import { ThumbsUp, X } from "react-feather";

const GetInTouch = () => {
  const dispatch = useDispatch();
  const isGetInTouch = useSelector((state) => state.isGetInTouch);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [occupation, setOccupation] = useState("");
  const [organization, setOrganization] = useState("");
  const userName = useSelector((state) => state.userName);
  const [submissionStatus, setSubmissionStatus] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  return (
    <Modal
      parentSelector={() => document.querySelector("#side")}
      contentLabel="Get In Touch Form"
      ariaHideApp={false}
      isOpen={isGetInTouch}
      style={{
        overlay: {
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 8000000000,
        },
        content: {
          padding: "0px",
          margin: "auto auto",
          inset: null,
          width: "550px",
          position: "relative",
          marginTop: "25vh",
        },
      }}
    >
      {submissionStatus === false ? (
        <Box flexDirection="column" px={6} py={5}>
          <Box flexBasis="row" alignItems="flex-end">
            <Box
              color="#ACAAAB"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch({
                  type: UPDATE_IS_GET_IN_TOUCH,
                  isGetInTouch: false,
                });
              }}
            >
              <X />
            </Box>
          </Box>

          <H5 mt={3}>
            Thank you for getting in touch with us{" "}
            {userName ? userName.split(" ")[0] : null}!
          </H5>

          <Paragraph mt={3}>
            Write down your contact details below and we'll send a follow-up
            email so that we can schedule a demo with you!
          </Paragraph>

          <Input
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            mt={3}
            placeholder="Full Name"
            width="100%"
          />

          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            mt={2}
            placeholder="Email"
            width="100%"
          />

          <Input
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
            mt={2}
            placeholder="Occupation"
            width="100%"
          />

          <Input
            mt={2}
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            placeholder="Company / School"
            width="100%"
          />

          <Box mt={5} flexDirection="row">
            <Button
              variant="secondary"
              buttonColor="accent.3"
              onClick={() => {
                dispatch({
                  type: UPDATE_IS_GET_IN_TOUCH,
                  isGetInTouch: false,
                });
              }}
            >
              MAYBE LATER
            </Button>

            <Button
              ml={2}
              disabled={isSubmittingForm ? true : false}
              variant="primary"
              buttonColor="accent.3"
              onClick={() => {
                setIsSubmittingForm(true);
                telemetry.getInTouch({ email });
                api
                  .post("/get-in-touch", {
                    email,
                    fullname,
                    occupation,
                    organization,
                  })
                  .then(() => {
                    setSubmissionStatus(true);
                    setIsSubmittingForm(false);
                  });
              }}
            >
              {isSubmittingForm ? "GETTING TO KNOW YOU ..." : "GET IN TOUCH"}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          px={8}
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width="80px"
            height="80px"
            borderRadius="100px"
            backgroundColor="rgba(82, 103, 180, 0.1)"
          >
            <ThumbsUp color="#5268B4"></ThumbsUp>
          </Box>

          <H5 mt={3}>
            Nice to meet you {userName ? userName.split(" ")[0] : fullname}!
          </H5>

          <Paragraph textAlign="center">
            We'll keep in touch! We're looking forward to discussing more with
            you, we'll send you an email as soon as possible.
          </Paragraph>

          <Button
            mt={5}
            variant="primary"
            buttonColor="sanmarino"
            onClick={() => {
              dispatch({
                type: UPDATE_IS_GET_IN_TOUCH,
                isGetInTouch: false,
              });
            }}
          >
            THAT'S GREAT!
          </Button>
        </Box>
      )}
    </Modal>
  );
};
export default GetInTouch;
