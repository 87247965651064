// From https://gist.github.com/graydon/11198540
export default {
  PH: [114.0952145, 4.2158064, 126.8072562, 21.3217806],
  SG: [103.6920359, 1.1304753, 104.0120359, 1.4504753],
  ID: [94.7717124, -11.2085669, 6.2744496, 141.0194444],
  MM: [92.1719423, 9.4399432, 101.1700796, 28.547835],
  MY: [105.3471939, -5.1076241, 120.3471939, 9.8923759],
  TH: [97.3438072, 5.612851, 105.636812, 20.4648337],
  VN: [102.14441, 8.1790665, 114.3337595, 23.393395],
};
