import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { FlyToInterpolator } from "deck.gl";
import { Text, Box, Button, theme, Link } from "@thinkingmachines/asimov";
import api from "../api";

import axios from "axios";
import * as telemetry from "../telemetry";
import {
  UPDATE_ISWARNING,
  UPDATE_MAPLAYERS,
  UPDATE_DATASET_INFO,
  UPDATE_VIEWPORT,
  UPDATE_MAP_LOADING_PROGRESS,
  UPDATE_LEGENDS,
  UPDATE_MAP_HAS_BEEN_LOADED,
  UPDATE_DATASET_INFO_MODAL,
  UPDATE_DOWNLOAD_DATASET_MODAL,
  UPDATE_LOADED_DATASETS_COUNT,
  UPDATE_IS_GET_IN_TOUCH,
} from "../store";

import bbox from "@turf/bbox";
import { WebMercatorViewport } from "@math.gl/web-mercator";
import { Info } from "react-feather";

const Card = styled(
  ({ className, data, onClick = () => {}, loadData, isLoaded = true }) => {
    const [selectedDataset, setSelectedDataset] = useState(null);
    const dispatch = useDispatch();

    const openDatasetInformation = () => {
      onClick();
      dispatch({
        type: UPDATE_DATASET_INFO_MODAL,
        isDatasetInfoOpen: true,
      });
    };

    const openDownloadInformation = () => {
      onClick();
      dispatch({
        type: UPDATE_DOWNLOAD_DATASET_MODAL,
        isDownloadDatasetOpen: true,
      });
    };

    return (
      <Box
        display="block"
        borderBottom="solid"
        borderWidth="1px"
        borderColor="#D6D3D5"
        className={className}
        bg="white"
        margin="10px 0px"
        borderRadius="4px"
        flexDirection="column"
        padding="15px"
        onMouseEnter={() => setSelectedDataset(data.id)}
        onMouseLeave={() => setSelectedDataset(null)}
      >
        <Box>
          <Text fontWeight="bold" fontSize="16px" color="gray.0">
            {data.title}
          </Text>
          {/* {isPrivileged ? (
            <Text fontWeight="light" fontSize="12px" color="gray.2">
              {data.id}
            </Text>
          ) : null} */}
          <Text
            fontSize="14px"
            fontWeight="small"
            color="gray.0"
            margin="12px 0px"
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
            }}
          >
            {data.description}
          </Text>
        </Box>

        {selectedDataset === data.id || isLoaded ? (
          <Box flexDirection="row" mt={3}>
            {data.can_load ? (
              <Button
                variant="primary"
                buttonColor="accent.3"
                onClick={(e) =>
                  e.stopPropagation() ||
                  loadData({
                    datasetInfo: data,
                    isLoaded,
                  })
                }
              >
                {isLoaded ? "REMOVE FROM MAP" : "ADD TO MAP"}
              </Button>
            ) : null}

            <Button
              ml={2}
              onClick={openDatasetInformation}
              variant="tertiary"
              buttonColor="accent.3"
            >
              VIEW DETAILS
            </Button>
            {data.can_download ? (
              <Button
                ml={2}
                onClick={openDownloadInformation}
                variant="tertiary"
                buttonColor="accent.3"
              >
                DOWNLOAD
              </Button>
            ) : null}
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>
    );
  }
)`
  cursor: pointer;
  &:hover {
    background: ${theme.colors.gray[6]};
  }
`;

const Datasets = () => {
  const datasets = useSelector((state) => state.datasets);
  const mapLayers = useSelector((state) => state.mapLayers);
  const viewport = useSelector((state) => state.viewport);
  const legends = useSelector((state) => state.legends);

  const loadedDatasetsCount = useSelector((state) => state.loadedDatasetsCount);
  const mapHasBeenLoaded = useSelector((state) => state.mapHasBeenLoaded);

  const dispatch = useDispatch();
  const loadData = ({ datasetInfo, isLoaded }) => {
    const { id, categories, geographies } = datasetInfo;
    // Close warning when loading
    // Remove data if it is already loaded. Else remove
    if (isLoaded) {
      const { [id]: omittedLayers, ...newGeoJson } = mapLayers;
      const { [id]: omittedLegends, ...newLegends } = legends;
      dispatch({ type: UPDATE_MAPLAYERS, mapLayers: newGeoJson });
      dispatch({ type: UPDATE_LEGENDS, legends: newLegends });

      // Subtract from loaded datasets count
      let count = loadedDatasetsCount;
      count--;
      dispatch({
        type: UPDATE_LOADED_DATASETS_COUNT,
        loadedDatasetsCount: count,
      });
    } else {
      telemetry.logLoadData({
        datasetId: id,
        loadedData: Object.keys(mapLayers),
        categories,
        geographies,
      });
      dispatch({
        type: UPDATE_ISWARNING,
        isWarning: false,
        isMapLoading: true,
        mapLoadingProgress: 0,
      });
      api
        .get(`/dataset/download/${id}`)
        .then(({ data }) =>
          // Remove credentials
          //https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
          axios
            .get(data.redirect_url, {
              withCredentials: false,
              onDownloadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                dispatch({
                  type: UPDATE_MAP_LOADING_PROGRESS,
                  mapLoadingProgress: percentCompleted,
                });
              },
            })
            .then(({ data }) => {
              dispatch({
                type: UPDATE_MAPLAYERS,
                mapLayers: {
                  ...mapLayers,
                  [id]: { data, datasetInfo, isVisible: true },
                },
                isMapLoading: false,
              });

              let inviteCount = mapHasBeenLoaded;
              inviteCount++;

              dispatch({
                type: UPDATE_MAP_HAS_BEEN_LOADED,
                mapHasBeenLoaded: inviteCount,
              });

              // Add to loaded datasets count
              let count = loadedDatasetsCount;
              count++;
              dispatch({
                type: UPDATE_LOADED_DATASETS_COUNT,
                loadedDatasetsCount: count,
              });

              const box = bbox(data);
              const { longitude, latitude, zoom } = new WebMercatorViewport(
                viewport
              ).fitBounds([box.slice(0, 2), box.slice(2, 4)], { padding: 20 });
              dispatch({
                type: UPDATE_VIEWPORT,
                viewport: {
                  ...viewport,
                  longitude,
                  latitude,
                  zoom: zoom,
                  transitionDuration: 1000,
                  transitionInterpolator: new FlyToInterpolator(),
                },
              });
            })
        )
        .catch((e) => {
          dispatch({
            type: UPDATE_ISWARNING,
            isWarning: true,
            isMapLoading: false,
          });
        });
    }
  };
  const openDatasetInfo = (datasetInfo) =>
    dispatch({ type: UPDATE_DATASET_INFO, datasetInfo });

  return (
    <Box margin="0px 0px" padding="0px">
      <Box display="block">
        {datasets.map((data, key) => (
          <Card
            key={key}
            data={data}
            onClick={() => openDatasetInfo(data)}
            loadData={loadData}
            isLoaded={mapLayers.hasOwnProperty(data.id)}
          />
        ))}

        <Box
          flexDirection="row"
          backgroundColor="#F1F3F9"
          alignItems="center"
          px={3}
          py={3}
          my={4}
        >
          <Box color="sanmarino">
            <Info></Info>
          </Box>
          <Text fontSize="14px" ml={3} lineHeight="23px">
            Need more data?{" "}
            <Link
              onClick={() => {
                dispatch({
                  type: UPDATE_IS_GET_IN_TOUCH,
                  isGetInTouch: true,
                });
              }}
              fontWeight="bold"
              fontSize="14px"
              color="sanmarino"
              style={{
                cursor: "pointer",
              }}
            >
              Get in touch
            </Link>{" "}
            with us to access more.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default Datasets;
