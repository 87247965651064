import React from "react";

import Modal from "react-modal";
import { Box, H5, Paragraph, Button } from "@thinkingmachines/asimov";

import { useDispatch, useSelector } from "react-redux";

import * as telemetry from "../telemetry";
import {
  UPDATE_DATASET_INFO,
  UPDATE_IS_GET_IN_TOUCH,
  UPDATE_DOWNLOAD_DATASET_MODAL,
} from "../store";

import api from "../api";

import axios from "axios";
import { X } from "react-feather";

const DownloadDataset = () => {
  const dispatch = useDispatch();
  const isDownloadDatasetOpen = useSelector(
    (state) => state.isDownloadDatasetOpen
  );

  const data = useSelector((state) => state.datasetInfo);
  const isPrivate = data ? !data.can_download : true;
  const downloadData = ({ datasetInfo }) => {
    const { id, content_type } = datasetInfo;

    telemetry.logDownloadDataset(id);
    api.get(`/dataset/download/${id}`).then(({ data }) => {
      // modified from: https://www.aspsnippets.com/Articles/Download-PDF-File-on-Button-Click-using-JavaScript.aspx
      axios
        .get(data.redirect_url, {
          withCredentials: false,
        })
        .then(({ data }) => {
          const json_data = JSON.stringify(data);
          const fileName = `${id}.geojson`;
          const blob = new Blob([json_data], { type: content_type });
          const url = window.URL || window.webkitURL;
          const link = url.createObjectURL(blob);
          const a = document.createElement("a");
          a.setAttribute("download", fileName);
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    });
  };
  return (
    <Modal
      parentSelector={() => document.querySelector("#side")}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      style={{
        overlay: {
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 8000000000,
        },
        content: {
          padding: "0px",
          margin: "auto auto",
          inset: null,
          width: "40vw",
          position: "relative",
          marginTop: "25vh",
        },
      }}
      isOpen={Boolean(data && isDownloadDatasetOpen)}
      onRequestClose={() =>
        dispatch({ type: UPDATE_DATASET_INFO, datasetInfo: null })
      }
    >
      {data ? (
        <Box flexDirection="column" height="100%" pt={5} pb={5}>
          <Box pl={5} pr={5} flexBasis="row" alignItems="flex-end">
            <Box
              color="#ACAAAB"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch({
                  type: UPDATE_DOWNLOAD_DATASET_MODAL,
                  isDownloadDatasetOpen: false,
                });
              }}
            >
              <X></X>
            </Box>
          </Box>

          <Box mt={3} overflowY="scroll" flexGrow="1">
            <Box pl={5} pr={5} flexDirection="row">
              <H5>PLEASE READ BEFORE DOWNLOADING</H5>
            </Box>

            <Box pl={5} pr={5} mt={2}>
              <Paragraph fontSize="14px">
                This file is not owned by Thinking Machines, and was acquired
                from an external data source. If you are going to cite its
                source, please refer to its original source. Thinking Machines
                is and will never be liable on the accuracy of this data.
              </Paragraph>
              <Paragraph fontSize="14px">
                If you agree to this, please proceed to downloading this file.
              </Paragraph>
            </Box>
          </Box>

          <Box mt={10} pl={5} pr={5} flexDirection="row" alignItems="center">
            <Button
              variant="primary"
              buttonColor="accent.3"
              onClick={() => {
                downloadData({ datasetInfo: data });
                dispatch({
                  type: UPDATE_DOWNLOAD_DATASET_MODAL,
                  isDownloadDatasetOpen: false,
                });
              }}
            >
              I understand. Download Now.
            </Button>
          </Box>
        </Box>
      ) : null}
    </Modal>
  );
};
export default DownloadDataset;
