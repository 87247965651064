import React from "react";
import { Panel } from "@thinkingmachines/geofront";
import { useSelector } from "react-redux";

import ColorBar from "./ColorBar";

const legendType = {
  categorical: "categorical",
  sequential: "sequential",
  diverging: "diverging",
  none: "none",
};
const Legends = () => {
  const legends = useSelector((state) => state.legends);
  const mapLayers = useSelector((state) => state.mapLayers);
  return legends ? (
    <Panel top="10px" left="10px" minWidth="200px" maxWidth="300px">
      {Object.keys(legends).map((key) => {
        const legend = legends[key];
        if (
          [legendType.sequential, legendType.diverging].includes(legend.type)
        ) {
          return (
            <ColorBar
              key={key}
              colors={legend.colors}
              dataset={mapLayers[key].datasetInfo.title}
              field={legend.field}
              min={legend.minValue}
              max={legend.maxValue}
            />
          );
        } else {
          return null;
        }
      })}
    </Panel>
  ) : null;
};
export default Legends;
