import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Text, Tabs, Tab } from "@thinkingmachines/asimov";
import { useDispatch } from "react-redux";
import "../App.css";
import api from "../api";
import Search from "./Search";
import Datasets from "./Datasets";
import LoadedDatasets from "./LoadedDatasets";
import DatasetInfo from "./DatasetInfo";
import DownloadDataset from "./DownloadDataset";
import Spinner from "./Spinner";

import { Info } from "react-feather";
import { Layers } from "react-feather";

import { UPDATE_DATASETS, UPDATE_SEARCH } from "../store";

const SHOWCASE_DATASETS = [
  "tm-geodata-warehouse.catalog.phl_admin_level4",
  "tm-geodata-warehouse.catalog.phl_poi_all",
  "tm-geodata-warehouse.catalog.phl_facebook_device_network_travel",
  "tm-geodata-warehouse.ph_admin_boundary.level1_region",
  "tm-geodata-warehouse.hrsl_phl_agg.hrsl_by_bgy_v2",
  "tm-geodata-warehouse.small_area_poverty_estimates.sape_clean_shape_files_2009_2012_2015",
];

function Catalog() {
  const isTableLoading = useSelector((state) => state.isTableLoading);
  const search = useSelector((state) => state.search);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const dispatch = useDispatch();

  const loadedDatasetsCount = useSelector((state) => state.loadedDatasetsCount);

  // Load showcase data on load
  useEffect(() => {
    dispatch({
      type: UPDATE_DATASETS,
      datasets: [],
      datasetView: "showcase",
      isTableLoading: true,
    });
    dispatch({
      type: UPDATE_SEARCH,
      isTableLoading: true,
    }); // Reset search value
    async function getShowcaseDataset() {
      const getDatasetInfo = (id) =>
        api.get(`/dataset/${id}`, {}).catch((error) => null);
      const results = await Promise.all(SHOWCASE_DATASETS.map(getDatasetInfo));
      const datasets = results.filter(Boolean).map((r) => r.data);
      dispatch({
        type: UPDATE_DATASETS,
        datasets,
        datasetView: "showcase",
        isTableLoading: false,
      });
    }
    getShowcaseDataset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Load or reload all data when filters or showUnloadable changes
  useEffect(() => {
    if (!isFirstRender) {
      dispatch({
        type: UPDATE_DATASETS,
        datasets: [],
        isTableLoading: true,
      });
      api
        .get(`/dataset/search`, {
          params: {
            q: search.searchValue,
            geographies: search.geographyFilter && search.geographyFilter.value,
            categories: search.categoryFilter && search.categoryFilter.value,
            show_unloadable: search.showUnloadable,
          },
        })
        .then((response) => {
          dispatch({
            type: UPDATE_DATASETS,
            datasets: response.data,
            isTableLoading: false,
          });
        });
    } else {
      setIsFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    search.categoryFilter,
    search.geographyFilter,
    search.showUnloadable,
  ]);
  return (
    <>
      <DatasetInfo />
      <DownloadDataset />
      <Tabs mt={5} tabColor="accent.3">
        <Tab title="Explore Datasets">
          <Box height="75vh">
            <Box m={1} flexDirection="column">
              <Box m={1} flexDirection="row">
                <Search />
              </Box>
            </Box>

            <Box height="90%" overflowY="scroll">
              {isTableLoading ? <Spinner /> : <Datasets />}
            </Box>
          </Box>
        </Tab>
        <Tab title="Datasets On Map">
          <Box height="75vh">
            {loadedDatasetsCount > 0 ? (
              <Box
                flexDirection="row"
                backgroundColor="#F1F3F9"
                alignItems="center"
                justifyContent="center"
                px={3}
                py={3}
                my={4}
              >
                <Box color="sanmarino">
                  <Layers></Layers>
                </Box>
                <Text fontSize="14px" ml={3} lineHeight="23px">
                  While viewing multiple datasets is pretty cool, it could get
                  messy. You can choose to show or hide your datasets
                  temporarily by toggling the visibility button.
                </Text>
              </Box>
            ) : (
              <Box
                flexDirection="row"
                backgroundColor="#F1F3F9"
                alignItems="center"
                justifyContent="center"
                px={3}
                py={3}
                my={4}
              >
                <Box color="sanmarino">
                  <Info></Info>
                </Box>
                <Text fontSize="14px" ml={3} lineHeight="23px">
                  You don't have any datasets on your the map yet. Head over to
                  the{" "}
                  <Text fontSize="14px" fontWeight="bold" color="sanmarino">
                    Explore Datasets Tab
                  </Text>{" "}
                  to look for datasets that you're interested in.
                </Text>
              </Box>
            )}
            <Box height="90%" overflowY="scroll">
              <LoadedDatasets></LoadedDatasets>
            </Box>
          </Box>
        </Tab>
      </Tabs>
    </>
  );
}

export default Catalog;
