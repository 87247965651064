import React, { useState } from "react";
import { Box, Button } from "@thinkingmachines/asimov";
import Searchbar from "./Searchbar";
import Filters, { ResetFilters } from "./Filters";

import { Search } from "react-feather";

export default () => {
  const [showSearchbar, setShowSearchbar] = useState(false);

  return (
    <Box flexDirection="column" py="4" width="100%">
      <Box flexDirection="row" alignItems="center">
        {showSearchbar === false ? <Filters /> : null}
        {showSearchbar === false ? <ResetFilters ml={2} /> : null}

        {showSearchbar === true ? <Searchbar /> : null}
        {showSearchbar === true ? (
          <Button
            variant="tertiary"
            buttonColor="accent.3"
            size="small"
            onClick={() => setShowSearchbar(false)}
          >
            CANCEL
          </Button>
        ) : (
          <Button
            onClick={() => setShowSearchbar(true)}
            variant="tertiary"
            buttonColor="accent.3"
            marginLeft="auto"
          >
            <Search></Search>
          </Button>
        )}
      </Box>
    </Box>
  );
};
