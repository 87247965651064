import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebaseui/dist/firebaseui.css";
import {
  Box,
  Text,
  Link,
  H5,
  H1,
  Paragraph,
  Button,
} from "@thinkingmachines/asimov";
import { useDispatch } from "react-redux";
import { UPDATE_USER, UPDATE_IS_GET_IN_TOUCH } from "../store";
import api from "../api";
import * as telemetry from "../telemetry";
import Map from "./Map";
import { ReactComponent as GeodataLogo } from "../assets/geodata_logo.svg";
import "../App.css";
import GetInTouch from "./GetInTouch";
import CircularProgress from "@material-ui/core/CircularProgress";

const firebaseui = require("firebaseui");

function Login({ children }) {
  const dispath = useDispatch();
  const [isRegistered, setIsRegistered] = useState(true);
  const [isLoadingApp, setIsLoadingApp] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setIsLoadingApp(false);
        const ui =
          firebaseui.auth.AuthUI.getInstance() ||
          new firebaseui.auth.AuthUI(firebase.auth());
        ui.start("#firebaseui-auth-container", {
          callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {},
          },
          signInFlow: "popup",
          signInOptions: [
            // List of OAuth providers supported.
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          ],
        });
      } else {
        user.getIdToken().then((token) => {
          api
            .post(`/login`, {
              email: user.email,
              token,
            })
            .then(({ data }) => {
              telemetry.registerUser({ email: user.email });
              if (!data.is_valid) {
                firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    dispath({ type: UPDATE_USER, user: null });
                    setIsRegistered(false);
                  });

                setIsLoadingApp(false);
              } else {
                dispath({
                  type: UPDATE_USER,
                  user,
                  isPrivileged: data.is_privileged,
                });

                setIsLoadingApp(true);
              }
            });
        });
      }
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {isLoadingApp === true ? (
        children
      ) : isLoadingApp === false ? (
        <Box flexDirection="row" height="100%" width="100%">
          <GetInTouch></GetInTouch>
          <Box
            flexDirection="column"
            id="side"
            width="45%"
            bg="white"
            px="6"
            paddingBottom="5"
            paddingTop="60px"
            overflowY="auto"
          >
            <Box width="100px" height="100px">
              <GeodataLogo height="100%" />
            </Box>

            <Box mt={5}>
              <H5 color="sanmarino" fontSize="16px">
                GEODATA CATALOG
              </H5>
            </Box>

            <Box>
              <H1>Insights are just waiting to be uncovered</H1>
            </Box>

            <Paragraph mt={1}>
              Get access to a wide range of up-to-date datasets. Discover which
              datasets would be valuable in coming up with a geospatial solution
              for your use case.
            </Paragraph>

            <Box flexDirection="column" mt={5}>
              <Box flexDirection="row" alignItems="center">
                <Box
                  justifyContent="center"
                  alignItems="center"
                  width="35px"
                  height="35px"
                  border="solid"
                  borderColor="sanmarino"
                  borderWidth="2px"
                  borderRadius="100px"
                >
                  <Text fontWeight="bold" color="sanmarino">
                    1
                  </Text>
                </Box>
                <Text ml={3}>
                  Browse through the latest datasets available in our repository
                </Text>
              </Box>

              <Box flexDirection="row" alignItems="center" mt={2}>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  width="35px"
                  height="35px"
                  border="solid"
                  borderColor="sanmarino"
                  borderWidth="2px"
                  borderRadius="100px"
                >
                  <Text fontWeight="bold" color="sanmarino">
                    2
                  </Text>
                </Box>
                <Text ml={3}>
                  Load the datasets you’re interested in into the map to uncover
                  insights
                </Text>
              </Box>

              <Box flexDirection="row" alignItems="center" mt={2}>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  width="35px"
                  height="35px"
                  border="solid"
                  borderColor="sanmarino"
                  borderWidth="2px"
                  borderRadius="100px"
                >
                  <Text fontWeight="bold" color="sanmarino">
                    3
                  </Text>
                </Box>
                <Text ml={3}>
                  Learn more about the available datasets and how they’re being
                  used
                </Text>
              </Box>
            </Box>

            <Box flexGrow="1" mt={5}>
              <Box flexDirection="row" alignItems="center">
                <Box>
                  <Button
                    variant="primary"
                    buttonColor="accent.3"
                    onClick={() => {
                      dispath({
                        type: UPDATE_IS_GET_IN_TOUCH,
                        isGetInTouch: true,
                      });
                    }}
                  >
                    GET IN TOUCH
                  </Button>
                </Box>
                {isRegistered ? (
                  <Box id="firebaseui-auth-container" />
                ) : (
                  <Text fontSize="16px" ml={4}>
                    It seems you're not yet registered. Get in touch with us to
                    join the waitlist.
                  </Text>
                )}
              </Box>
            </Box>

            <Paragraph mt={3} fontSize="14px" color="#858384">
              <Text fontWeight="bold">THINKING MACHINES</Text> offers a
              Geospatial Artificial Intelligence solution that leverages these
              datasets to help organizations of all shapes and sizes make
              decisions using the power of spatial analysis.{" "}
              <Link
                href="https://thinkingmachin.es/services/geo-ai/?utm_source=demo&utm_medium=geodata&utm_campaign=learnmore"
                target="blank"
                color="sanmarino"
              >
                Learn more
              </Link>
            </Paragraph>
          </Box>

          <Box style={{ position: "relative", width: "55%", height: "100%" }}>
            <Map />
          </Box>
        </Box>
      ) : (
        <Box
          width="100vw"
          height="100vh"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress style={{ width: "80px", height: "80px" }} />
          <Text fontSize="16px" mt={8}>
            Preparing Geodata Catalog ...
          </Text>
        </Box>
      )}
    </>
  );
}

export default Login;
