import React, { useState } from "react";
import { Box, Button } from "@thinkingmachines/asimov";
import { ReactComponent as GeodataLogo } from "../assets/geodata_logo.svg";
import firebase from "firebase/app";
import "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_USER,
  UPDATE_MAPLAYERS,
  UPDATE_MAP_HAS_BEEN_LOADED,
  UPDATE_IS_GET_IN_TOUCH,
} from "../store";
import * as telemetry from "../telemetry";

import { NavLink } from "react-router-dom";

import { useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const Header = () => {
  const dispath = useDispatch();
  const history = useHistory();
  const userPhoto = useSelector((state) => state.userPhoto);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      width="100%"
      height="64px"
      padding="0"
      flexDirection="row"
      justifyContent="center"
    >
      <Box marginTop="auto" flexDirection="row" height="100%" flexGrow="1">
        <GeodataLogo height="100%" />
        <Box flexDirection="row" alignItems="center" ml={3}>
          <NavLink
            style={{ textDecoration: "none", color: "#858384" }}
            activeStyle={{
              color: "#3C3A3B",
              fontWeight: "bold",
              textDecoration: "none",
            }}
            exact
            to="/"
          >
            Home
          </NavLink>
          <NavLink
            style={{
              textDecoration: "none",
              color: "#858384",
              marginInlineStart: "15px",
            }}
            activeStyle={{
              color: "#3C3A3B",
              fontWeight: "bold",
              textDecoration: "none",
            }}
            to="/catalog"
          >
            Catalog
          </NavLink>
        </Box>
      </Box>

      <Box justifyContent="center">
        <Button
          variant="tertiary"
          buttonColor="sanmarino"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            dispath({
              type: UPDATE_IS_GET_IN_TOUCH,
              isGetInTouch: true,
            });
          }}
        >
          Get In Touch
        </Button>
      </Box>

      <Button variant="tertiary" buttonColor="sanmarino" onClick={handleClick}>
        <Box
          px={1}
          py={1}
          width="50px"
          height="50px"
          border="solid"
          borderWidth="2px"
          borderColor="sanmarino"
        >
          <img alt="user" src={userPhoto} width="100%" height="100%" />
        </Box>
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ top: "60px" }}
      >
        <MenuItem
          onClick={() => {
            telemetry.unregisterUser();
            firebase
              .auth()
              .signOut()
              .then(() => {
                dispath({ type: UPDATE_USER, user: null });
              });
            dispath({ type: UPDATE_MAPLAYERS, mapLayers: {} });
            dispath({
              type: UPDATE_MAP_HAS_BEEN_LOADED,
              mapHasBeenLoaded: false,
            });
            history.push("/");
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Header;
