import React from "react";
import { Box, Text, H5, H1, Paragraph, Link } from "@thinkingmachines/asimov";
import { ReactComponent as GeodataLogo } from "../assets/geodata_logo.svg";

import { Info } from "react-feather";

const DesktopOnly = () => (
  <Box
    flexDirection="column"
    id="side"
    width="100%"
    bg="white"
    px="6"
    py="5"
    mt={8}
  >
    <Box flexDirection="row" justifyContent="center">
      <Box width="100px" height="100px">
        <GeodataLogo height="100%" />
      </Box>
    </Box>

    <Box mt={5} justifyContent="center" flexDirection="row">
      <H5 color="sanmarino" fontSize="16px">
        GEODATA CATALOG
      </H5>
    </Box>

    <Box justifyContent="center" flexDirection="row">
      <H1 textAlign="center">Insights are just waiting to be uncovered</H1>
    </Box>

    <Paragraph mt={1} textAlign="center">
      Get access to a wide range of up-to-date datasets. Discover which datasets
      would be valuable in coming up with a geospatial solution for your use
      case.
    </Paragraph>

    <Box flexDirection="column" mt={8}>
      <Box flexDirection="row" alignItems="center">
        <Box width="20%">
          <Box
            justifyContent="center"
            alignItems="center"
            width="35px"
            height="35px"
            border="solid"
            borderColor="sanmarino"
            borderWidth="2px"
            borderRadius="100px"
          >
            <Text fontWeight="bold" color="sanmarino">
              1
            </Text>
          </Box>
        </Box>

        <Box width="80%">
          <Text>
            Browse through the latest datasets available in our repository
          </Text>
        </Box>
      </Box>

      <Box flexDirection="row" alignItems="center" mt={4}>
        <Box width="20%">
          <Box
            justifyContent="center"
            alignItems="center"
            width="35px"
            height="35px"
            border="solid"
            borderColor="sanmarino"
            borderWidth="2px"
            borderRadius="100px"
          >
            <Text fontWeight="bold" color="sanmarino">
              2
            </Text>
          </Box>
        </Box>
        <Box width="80%">
          <Text>
            Load the datasets you’re interested in into the map to uncover
            insights
          </Text>
        </Box>
      </Box>

      <Box flexDirection="row" alignItems="center" mt={4}>
        <Box width="20%">
          <Box
            justifyContent="center"
            alignItems="center"
            width="35px"
            height="35px"
            border="solid"
            borderColor="sanmarino"
            borderWidth="2px"
            borderRadius="100px"
          >
            <Text fontWeight="bold" color="sanmarino">
              3
            </Text>
          </Box>
        </Box>
        <Box width="80%">
          <Text>
            Learn more about the available datasets and how they’re being used
          </Text>
        </Box>
      </Box>
    </Box>

    <Box
      flexDirection="column"
      backgroundColor="#F1F3F9"
      alignItems="center"
      justifyContent="center"
      px={3}
      py={3}
      mt={8}
    >
      <Box color="sanmarino">
        <Info></Info>
      </Box>

      <H5 fontWeight="normal" color="sanmarino">
        We're not on mobile.
      </H5>
      <Text fontSize="14px" ml={3} lineHeight="23px" textAlign="center">
        It's best to view the catalog on a website. You can also visit our site,
        to learn more about{" "}
        <Link
          href="https://thinkingmachin.es/services/geo-ai/?utm_source=demo&utm_medium=geodata&utm_campaign=learnmore"
          color="sanmarino"
        >
          GeoAI
        </Link>
      </Text>
    </Box>
  </Box>
);
export default DesktopOnly;
