import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { FlyToInterpolator } from "deck.gl";
import { Text, Box, Button, theme, H5 } from "@thinkingmachines/asimov";
import api from "../api";

import axios from "axios";
import * as telemetry from "../telemetry";
import {
  UPDATE_ISWARNING,
  UPDATE_MAPLAYERS,
  UPDATE_DATASET_INFO,
  UPDATE_VIEWPORT,
  UPDATE_MAP_LOADING_PROGRESS,
  UPDATE_LEGENDS,
  UPDATE_MAP_HAS_BEEN_LOADED,
  UPDATE_VISIBILITY,
  UPDATE_DATASET_INFO_MODAL,
  UPDATE_LOADED_DATASETS_COUNT,
} from "../store";

import bbox from "@turf/bbox";
import { WebMercatorViewport } from "@math.gl/web-mercator";

import Switch from "@material-ui/core/Switch";

const Card = styled(
  ({ className, data, loadData, onClick = () => {}, isLoaded = true }) => {
    const isVisible = useSelector((state) =>
      state.mapLayers[data.id] ? state.mapLayers[data.id].isVisible : false
    );
    const [selectedDataset, setSelectedDataset] = useState(null);
    const dispatch = useDispatch();

    const openDatasetInformation = () => {
      onClick();
      dispatch({
        type: UPDATE_DATASET_INFO_MODAL,
        isDatasetInfoOpen: true,
      });
    };

    return (
      <Box>
        {isLoaded ? (
          <Box
            display="block"
            borderBottom="solid"
            borderWidth="1px"
            borderColor="#D6D3D5"
            className={className}
            bg="white"
            margin="10px 0px"
            borderRadius="4px"
            flexDirection="column"
            padding="15px"
            onMouseEnter={() => setSelectedDataset(data.id)}
            onMouseLeave={() => setSelectedDataset(null)}
          >
            <Box>
              <Text fontWeight="bold" fontSize="16px" color="gray.0">
                {data.title}
              </Text>

              <Text
                fontSize="14px"
                fontWeight="small"
                color="gray.0"
                margin="12px 0px"
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                }}
              >
                {data.description}
              </Text>
            </Box>

            {selectedDataset === data.id || isLoaded ? (
              <Box flexDirection="row" mt={3}>
                {data.can_load ? (
                  <Button
                    variant="primary"
                    buttonColor="accent.3"
                    onClick={(e) =>
                      e.stopPropagation() ||
                      loadData({
                        datasetInfo: data,
                        isLoaded,
                      })
                    }
                  >
                    {isLoaded ? "REMOVE FROM MAP" : "ADD TO MAP"}
                  </Button>
                ) : null}

                <Box flexDirection="row" flexGrow="1">
                  <Button
                    ml={2}
                    onClick={openDatasetInformation}
                    variant="tertiary"
                    buttonColor="accent.3"
                  >
                    VIEW DETAILS
                  </Button>
                </Box>

                <Box flexDirection="row">
                  <H5 fontSize="14px">VISIBLE ON MAP</H5>
                  <Switch
                    color="primary"
                    checked={isVisible}
                    onChange={(e) => {
                      dispatch({
                        type: UPDATE_VISIBILITY,
                        id: data.id,
                        isVisible: e.target.checked,
                      });
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <Box></Box>
            )}
          </Box>
        ) : null}
      </Box>
    );
  }
)`
  cursor: pointer;
  &:hover {
    background: ${theme.colors.gray[6]};
  }
`;

const LoadedDatasets = () => {
  const mapLayers = useSelector((state) => state.mapLayers);
  const viewport = useSelector((state) => state.viewport);
  const legends = useSelector((state) => state.legends);
  const dispatch = useDispatch();

  const loadedDatasetsCount = useSelector((state) => state.loadedDatasetsCount);

  const loadData = ({ datasetInfo, isLoaded }) => {
    const { id, categories, geographies } = datasetInfo;
    // Close warning when loading
    // Remove data if it is already loaded. Else remove
    if (isLoaded) {
      const { [id]: omittedLayers, ...newGeoJson } = mapLayers;
      const { [id]: omittedLegends, ...newLegends } = legends;
      dispatch({ type: UPDATE_MAPLAYERS, mapLayers: newGeoJson });
      dispatch({ type: UPDATE_LEGENDS, legends: newLegends });

      // Subtract from loaded datasets count
      let count = loadedDatasetsCount;
      count--;
      dispatch({
        type: UPDATE_LOADED_DATASETS_COUNT,
        loadedDatasetsCount: count,
      });
    } else {
      telemetry.logLoadData({
        datasetId: id,
        loadedData: Object.keys(mapLayers),
        categories,
        geographies,
      });
      dispatch({
        type: UPDATE_ISWARNING,
        isWarning: false,
        isMapLoading: true,
        mapLoadingProgress: 0,
      });
      api
        .get(`/dataset/download/${id}`)
        .then(({ data }) =>
          // Remove credentials
          //https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
          axios
            .get(data.redirect_url, {
              withCredentials: false,
              onDownloadProgress: function (progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                dispatch({
                  type: UPDATE_MAP_LOADING_PROGRESS,
                  mapLoadingProgress: percentCompleted,
                });
              },
            })
            .then(({ data }) => {
              dispatch({
                type: UPDATE_MAPLAYERS,
                mapLayers: {
                  ...mapLayers,
                  [id]: { data, datasetInfo, isVisible: true },
                },
                isMapLoading: false,
              });

              dispatch({
                type: UPDATE_MAP_HAS_BEEN_LOADED,
                mapHasBeenLoaded: true,
              });

              const box = bbox(data);
              const { longitude, latitude, zoom } = new WebMercatorViewport(
                viewport
              ).fitBounds([box.slice(0, 2), box.slice(2, 4)], { padding: 20 });
              dispatch({
                type: UPDATE_VIEWPORT,
                viewport: {
                  ...viewport,
                  longitude,
                  latitude,
                  zoom: zoom,
                  transitionDuration: 1000,
                  transitionInterpolator: new FlyToInterpolator(),
                },
              });
            })
        )
        .catch((e) => {
          dispatch({
            type: UPDATE_ISWARNING,
            isWarning: true,
            isMapLoading: false,
          });
        });
    }
  };
  const openDatasetInfo = (datasetInfo) =>
    dispatch({ type: UPDATE_DATASET_INFO, datasetInfo });
  return (
    <Box margin="0px 0px" padding="0px">
      <Box display="block">
        {Object.entries(mapLayers).map(([key, value]) => (
          <Card
            key={key}
            data={value.datasetInfo}
            onClick={() => openDatasetInfo(value.datasetInfo)}
            loadData={loadData}
            isLoaded={true}
          />
        ))}
      </Box>
    </Box>
  );
};
export default LoadedDatasets;
