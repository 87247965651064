import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Text } from "@thinkingmachines/asimov";

const LinearProgressWithLabel = (props) => (
  <Box
    alignItems="center"
    justifyContent="center"
    bg="white"
    flexDirection="row"
    px={5}
    py={4}
  >
    <Box>
      {Math.round(props.value) === 0 ? (
        <CircularProgress variant="indeterminate" />
      ) : (
        <CircularProgress variant="determinate" {...props} />
      )}
    </Box>

    <Box flexDirection="column" ml={4}>
      <Text fontWeight="bold" fontSize="18px" color="sanmarino">
        Plotting data on map ...
      </Text>
      <Text fontSize="16px" color="#3C3A3B">{`${Math.round(
        props.value
      )}%`}</Text>
    </Box>
  </Box>
);
export default LinearProgressWithLabel;
