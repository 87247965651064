import groupBy from "lodash/groupBy";
export const CATEGORY_MAPPING = {
  demographics: "Demographics",
  infrastructure: "Infrastructure",
  "points of interest": "Points of Interest",
  mobility: "Mobility",
  environmental: "Environmental",
  admin: "Admin Boundary",
  "admin boundary": "Admin Boundary",
};

export const GEOGRAPHY_MAPPING = {
  philippines: "Philippines",
  malaysia: "Malaysia",
  indonesia: "Indonesia",
  singapore: "Singapore",
  thailand: "Thailand",
  vietnam: "Vietnam",
};

// { label: 'Myanmar', value: ['myanmar', 'burma'] },
// { label: 'Cambodia', value: ['cambodia'] },
// { label: 'Laos', value: ['laos'] },
// { label: 'Brunei', value: ['brunei'] },
// { label: 'Timor-Leste', value: ['timor-leste', 'timor leste', 'east timor'] },

export const CATEGORY_LABELS = Object.entries(
  groupBy(Object.keys(CATEGORY_MAPPING), (key) => CATEGORY_MAPPING[key])
).map((entry) => ({
  label: entry[0],
  value: entry[1],
}));

export const GEOGRAPHY_LABELS = Object.entries(
  groupBy(Object.keys(GEOGRAPHY_MAPPING), (key) => GEOGRAPHY_MAPPING[key])
).map((entry) => ({
  label: entry[0],
  value: entry[1],
}));
