import gradstop from "gradstop";
import seededshuffle from "seededshuffle";

import { lighten } from "polished";

export const legendColors = [
  [83, 190, 208], // shakespeare
  [247, 187, 9], // buttercup
  [34, 146, 236], // picton blue
  [243, 162, 88], // sandy brown
  [82, 103, 180], // san marino
  [239, 70, 49], // terracotta
  [68, 188, 118], // emerald
];

export const legendColorsString = [
  "#53bed0", // shakespeare
  "#F7BB09", // buttercup
  "#2292EC", // piction blue
  "#F3A258", // sandy brown
  "#5267b4", // san marino
  "#ef5631", // terracotta
  "#44BC76", // emererald
];

export const hexToRgb = (hex) =>
  `rgb(${hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))
    .join()})`;

// generate random colors based on asimov colors
export const randomColor = ({ count, seed }) => {
  const stops = Math.ceil(count / legendColorsString.length);
  if (count <= legendColorsString.length) {
    return seededshuffle
      .shuffle(legendColorsString.map(hexToRgb), seed)
      .slice(0, count);
  } else {
    const colors = legendColorsString.flatMap((color) =>
      gradstop({
        stops: stops,
        inputFormat: "hex",
        colorArray: [color, lighten(0.4, color)],
      })
    );
    return seededshuffle.shuffle(colors, seed).slice(0, count);
  }
};
