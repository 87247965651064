import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Text,
  Button,
  H3,
  Paragraph,
  Link,
} from "@thinkingmachines/asimov";
import { UPDATE_IS_GET_IN_TOUCH } from "../store";
import "../App.css";

import { useHistory } from "react-router-dom";

function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.userName);

  return (
    <Box flexDirection="column" height="100%">
      <H3 mt={10}>Hi {userName ? userName.split(" ")[0] : null}!</H3>
      <Paragraph mt={3}>
        TM’s Geodata Catalog has a wide range of datasets under its belt that
        would be valuable in coming up with a geospatial solution. Insights from
        these datasets are just waiting to be uncovered!
      </Paragraph>

      <Box flexGrow="1" mt={3}>
        <Box flexDirection="row">
          <Button
            variant="secondary"
            buttonColor="accent.3"
            onClick={() => {
              dispatch({
                type: UPDATE_IS_GET_IN_TOUCH,
                isGetInTouch: true,
              });
            }}
          >
            GET IN TOUCH
          </Button>

          <Button
            ml={2}
            variant="primary"
            buttonColor="accent.3"
            onClick={() => {
              history.push("/catalog");
            }}
          >
            START BROWSING CATALOG
          </Button>
        </Box>
      </Box>

      <Paragraph mt={3} fontSize="14px" color="#858384">
        <Text fontWeight="bold">THINKING MACHINES</Text> offers a Geospatial
        Artificial Intelligence solution that leverages these datasets to help
        organizations of all shapes and sizes make decisions using the power of
        spatial analysis.{" "}
        <Link
          href="https://thinkingmachin.es/services/geo-ai/?utm_source=demo&utm_medium=geodata&utm_campaign=learnmore"
          target="blank"
          color="sanmarino"
        >
          Learn more
        </Link>
      </Paragraph>
    </Box>
  );
}

export default Home;
