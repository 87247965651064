import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@thinkingmachines/asimov";
import { useDispatch } from "react-redux";
import { isBrowser } from "react-device-detect";

import "./App.css";
import * as telemetry from "./telemetry";
import api from "./api";
import Map from "./components/Map";
import Header from "./components/Header";
import Login from "./components/Login";
import DesktopOnly from "./components/DesktopOnly";
import Catalog from "./components/Catalog";
import Home from "./components/Home";
import GetInTouch from "./components/GetInTouch";

import { Route, HashRouter as Router } from "react-router-dom";

import { UPDATE_DATASETS, UPDATE_SEARCH } from "./store";

const SHOWCASE_DATASETS = [
  "tm-geodata-warehouse.catalog.phl_admin_level4",
  "tm-geodata-warehouse.catalog.phl_poi_all",
  "tm-geodata-warehouse.ph_admin_boundary.level1_region",
  "tm-geodata-warehouse.hrsl_phl_agg.hrsl_by_bgy_v2",
  "tm-geodata-warehouse.small_area_poverty_estimates.sape_clean_shape_files_2009_2012_2015",
];

function App() {
  const search = useSelector((state) => state.search);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const dispatch = useDispatch();

  // Record loading of the page
  useEffect(() => {
    telemetry.loadPage();
  }, []);
  // Load showcase data on load
  useEffect(() => {
    dispatch({
      type: UPDATE_DATASETS,
      datasets: [],
      datasetView: "showcase",
      isTableLoading: true,
    });
    dispatch({
      type: UPDATE_SEARCH,
      isTableLoading: true,
    }); // Reset search value
    async function getShowcaseDataset() {
      const getDatasetInfo = (id) =>
        api.get(`/dataset/${id}`, {}).catch((error) => null);
      const results = await Promise.all(SHOWCASE_DATASETS.map(getDatasetInfo));
      const datasets = results.filter(Boolean).map((r) => r.data);
      dispatch({
        type: UPDATE_DATASETS,
        datasets,
        datasetView: "showcase",
        isTableLoading: false,
      });
    }
    getShowcaseDataset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Load or reload all data when filters or showUnloadable changes
  useEffect(() => {
    if (!isFirstRender) {
      dispatch({
        type: UPDATE_DATASETS,
        datasets: [],
        isTableLoading: true,
      });
      api
        .get(`/dataset/search`, {
          params: {
            q: search.searchValue,
            geographies: search.geographyFilter && search.geographyFilter.value,
            categories: search.categoryFilter && search.categoryFilter.value,
            show_unloadable: search.showUnloadable,
          },
        })
        .then((response) => {
          dispatch({
            type: UPDATE_DATASETS,
            datasets: response.data,
            isTableLoading: false,
          });
        });
    } else {
      setIsFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    search.categoryFilter,
    search.geographyFilter,
    search.showUnloadable,
  ]);
  return (
    <>
      <GetInTouch></GetInTouch>
      <Box flexDirection="row" height="100%" width="100%">
        {/* <DatasetInfo /> */}
        <Box id="side" width="45%" bg="white" px="6" py="5">
          <Router>
            <Header />
            <Route path="/" exact component={Home}></Route>
            <Route path="/catalog" component={Catalog}></Route>
          </Router>
        </Box>
        <Box style={{ position: "relative", width: "55%", height: "100%" }}>
          <Map />
        </Box>
      </Box>
    </>
  );
}
const AppWithLogin = () => {
  return isBrowser ? (
    <Login>
      <App />
    </Login>
  ) : (
    <DesktopOnly />
  );
};
export default AppWithLogin;
