import { createStore } from "redux";

const INITIAL_VIEWSTATE = {
  latitude: 9.45,
  longitude: 120.761,
  zoom: 3.7,
  bearing: 0,
  pitch: 0,
  minZoom: 0,
  maxZoom: 20,
};

const INITIAL_STATE = {
  datasetView: "showcase",
  user: null,
  isPrivileged: true,
  search: {
    categoryFilter: null,
    geographyFilter: null,
    searchValue: "",
    showUnloadable: false,
  },
  datasets: [],
  mapLayers: {},
  isTableLoading: false,
  isMapLoading: false,
  mapLoadingProgress: 0,
  mapHasBeenLoaded: false,
  isGetInTouch: false,
  isDatasetInfoOpen: false,
  isDownloadDatasetOpen: false,
  loadedDatasetsCount: 0,
  isWarning: false,
  datasetInfo: null,
  legends: {},
  viewport: INITIAL_VIEWSTATE,
};
export const UPDATE_DATASETS = "updateDataSets";
export const UPDATE_MAPLAYERS = "updateMapLayers";
export const UPDATE_ISWARNING = "updateIsWarning";
export const UPDATE_SEARCH = "updateSearch";
export const UPDATE_DATASET_INFO = "updateDatasetInfo";
export const UPDATE_LEGENDS = "updateLegends";
export const UPDATE_VIEWPORT = "updateViewport";
export const UPDATE_MAP_LOADING_PROGRESS = "updateMapLoadingProgress";
export const UPDATE_MAP_HAS_BEEN_LOADED = "mapHasBeenLoaded";
export const UPDATE_IS_GET_IN_TOUCH = "isGetInTouch";
export const UPDATE_USER = "updateUser";
export const UPDATE_VISIBILITY = "updateVisibility";
export const UPDATE_DATASET_INFO_MODAL = "updateDatasetInfoModal";
export const UPDATE_DOWNLOAD_DATASET_MODAL = "updateDownloadDatasetModal";
export const UPDATE_LOADED_DATASETS_COUNT = "updateLoadedDatasetsCount";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DATASETS: {
      const newState = {
        ...state,
        datasets: action.datasets,
        datasetView: action.datasetView || "search",
      };
      if ("isTableLoading" in action) {
        newState["isTableLoading"] = action.isTableLoading;
      }
      return newState;
    }
    case UPDATE_MAPLAYERS: {
      const newState = {
        ...state,
        mapLayers: action.mapLayers,
        mapLoadingProgress: 0, // Reset value once maplayer is updated
      };
      if ("isMapLoading" in action) {
        newState["isMapLoading"] = action.isTableLoading;
      }
      return newState;
    }
    case UPDATE_ISWARNING: {
      const newState = { ...state, isWarning: action.isWarning };
      if ("isMapLoading" in action) {
        newState["isMapLoading"] = action.isMapLoading;
      }
      if ("mapLoadingProgress" in action) {
        newState["mapLoadingProgress"] = action.mapLoadingProgress;
      }
      return newState;
    }
    case UPDATE_SEARCH: {
      const search = state.search;
      const newSearch = {
        ...search,
        ...action.search,
        datasetView: action.datasetView || "search",
      };
      const newState = { ...state, search: newSearch };
      if ("isTableLoading" in action) {
        newState["isTableLoading"] = action.isTableLoading;
      }

      return newState;
    }
    case UPDATE_DATASET_INFO:
      return { ...state, datasetInfo: action.datasetInfo };
    case UPDATE_LEGENDS:
      return { ...state, legends: action.legends };
    case UPDATE_VIEWPORT:
      return { ...state, viewport: action.viewport };
    case UPDATE_MAP_LOADING_PROGRESS:
      return { ...state, mapLoadingProgress: action.mapLoadingProgress };
    case UPDATE_MAP_HAS_BEEN_LOADED: {
      const newState = {
        ...state,
        mapHasBeenLoaded: action.mapHasBeenLoaded,
      };
      return newState;
    }
    case UPDATE_DATASET_INFO_MODAL: {
      const newState = {
        ...state,
        isDatasetInfoOpen: action.isDatasetInfoOpen,
      };
      return newState;
    }
    case UPDATE_DOWNLOAD_DATASET_MODAL: {
      const newState = {
        ...state,
        isDownloadDatasetOpen: action.isDownloadDatasetOpen,
      };
      return newState;
    }

    case UPDATE_IS_GET_IN_TOUCH: {
      const newState = {
        ...state,
        isGetInTouch: action.isGetInTouch,
      };
      return newState;
    }
    case UPDATE_LOADED_DATASETS_COUNT: {
      const newState = {
        ...state,
        loadedDatasetsCount: action.loadedDatasetsCount,
      };
      return newState;
    }
    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
        userName: action.user ? action.user.displayName : null,
        userPhoto: action.user ? action.user.photoURL : null,
        isPrivileged: action.isPrivileged,
      };
    case UPDATE_VISIBILITY:
      return {
        ...state,
        mapLayers: {
          ...state.mapLayers,
          [action.id]: {
            ...state.mapLayers[action.id],
            isVisible: action.isVisible,
          },
        },
      };
    default:
      return { ...state };
  }
};
const store = createStore(reducer, INITIAL_STATE);
export default store;
