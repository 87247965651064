//Helper function for telemetry
import amplitude from "amplitude-js";

import uniq from "lodash/uniq";

import { AMPLITUDE_KEY } from "./settings";
import { CATEGORY_MAPPING, GEOGRAPHY_MAPPING } from "./constants";

amplitude.getInstance().init(AMPLITUDE_KEY, null, {
  includeUtm: true,
  includeReferrer: true,
});

export const logLoadData = ({
  datasetId,
  loadedData,
  categories,
  geographies,
}) => {
  const mappedCategories = categories
    ? uniq(categories.map((k) => CATEGORY_MAPPING[k]).filter(Boolean))
    : [];
  const mappedGeographies = geographies
    ? uniq(geographies.map((k) => GEOGRAPHY_MAPPING[k]).filter(Boolean))
    : [];
  amplitude.getInstance().logEvent("loadData", {
    datasetId,
    loadedData: loadedData,
    categories: mappedCategories,
    geographies: mappedGeographies,
  });
};

export const logDownloadDataset = (datasetId) => {
  amplitude.getInstance().logEvent("downloadDataset", {
    datasetId,
  });
};

export const registerUser = ({ email }) => {
  // identify based on email. More complex apps would have a unique user id
  amplitude.getInstance().setUserId(email);
};
export const loadPage = () => {
  amplitude.getInstance().logEvent("pageLoad");
};

export const getInTouch = ({ email }) => {
  amplitude.getInstance().logEvent("getInTouch", { email });
};
export const unregisterUser = () => {
  amplitude.getInstance().setUserId(null);
};
