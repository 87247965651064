import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Select } from "@thinkingmachines/asimov";

import { UPDATE_SEARCH, UPDATE_DATASETS } from "../store";
import api from "../api";
import { CATEGORY_LABELS, GEOGRAPHY_LABELS } from "../constants";
const Filters = () => {
  const dispatch = useDispatch();
  const geographyFilter = useSelector((state) => state.search.geographyFilter);
  const categoryFilter = useSelector((state) => state.search.categoryFilter);

  return (
    <Box
      flexDirection="row"
      width="55%"
      justifyContent="space-between"
      zIndex="99"
    >
      <Select
        value={geographyFilter}
        color="accent.3"
        button={{
          borderColor: "transparent",
          backgroundColor: "#f7f7f7",
          height: "42px",
        }}
        wrapper={{
          width: "48%",
        }}
        placeholder="Geography"
        options={GEOGRAPHY_LABELS}
        onChange={(item) => {
          dispatch({
            type: UPDATE_SEARCH,
            search: { geographyFilter: item },
          });
        }}
      />
      <Select
        color="accent.3"
        value={categoryFilter}
        button={{
          borderColor: "transparent",
          backgroundColor: "#f7f7f7",
          height: "42px",
        }}
        wrapper={{
          width: "48%",
        }}
        placeholder="Category"
        options={CATEGORY_LABELS}
        onChange={(item) => {
          dispatch({
            type: UPDATE_SEARCH,
            search: { categoryFilter: item },
          });
        }}
      />
    </Box>
  );
};

export const ResetFilters = ({ ...props }) => {
  const dispatch = useDispatch();
  const resetFilters = () => {
    dispatch({
      type: UPDATE_SEARCH,
      search: {
        categoryFilter: null,
        geographyFilter: null,
        showUnloadable: false,
        searchValue: "",
      },
    }); // Reset search value
    api.get(`/dataset/search`).then((response) => {
      dispatch({
        type: UPDATE_DATASETS,
        datasets: response.data,
        isTableLoading: false,
      });
    });
  };
  return (
    <Button
      variant="tertiary"
      buttonColor="accent.3"
      size="small"
      marginTop="1"
      onClick={resetFilters}
      {...props}
    >
      Show All
    </Button>
  );
};
export default Filters;
