import React from "react";
import numeral from "numeral";
import { Box, Text } from "@thinkingmachines/asimov";
// Copied from geofront
const ColorBar = ({ dataset, field, min, max, colors }) => (
  <Box
    margin="5px 0px"
    padding="5px 10px 2px"
    backgroundColor="white"
    borderRadius="3px"
  >
    <Text fontSize="12px" fontWeight="bold">
      {dataset}
    </Text>
    <Text fontSize="10px">{field}</Text>
    <Box
      flexDirection="row"
      border="solid 1px rgb(133, 131, 132)"
      margin="0px"
      paddingBottom="10px"
      height="10px"
      opacity="0.6"
    >
      {colors.map((c, i) => (
        <Box
          width="100%"
          key={i}
          height="10px"
          backgroundColor={c}
          paddingBottom="10px"
        />
      ))}
    </Box>
    <Text>
      <Box flexDirection="row">
        <Text fontSize="10px">{numeral(min).format("0a")}</Text>
        <Text fontSize="10px" textAlign="right" margin="0px 0px 0px auto">
          {numeral(max).format("0a")}
        </Text>
      </Box>
    </Text>
  </Box>
);

export default ColorBar;
