import React, { useState, useEffect, useRef } from "react";
import DeckGL from "@deck.gl/react";
import { GeoJsonLayer } from "@deck.gl/layers";
import StaticMap, {
  _MapContext as MapContext,
  NavigationControl,
} from "react-map-gl";

import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { WebMercatorViewport } from "@math.gl/web-mercator";

import { Box, Text, Button, H5, Paragraph } from "@thinkingmachines/asimov";
import { Tooltip, useDeckGlTooltip } from "@thinkingmachines/geofront";

import gradstop from "gradstop";
import { darken, lighten } from "polished";
import uniq from "lodash/uniq";
import floor from "lodash/floor";
import max from "lodash/max";
import min from "lodash/min";

import {
  UPDATE_ISWARNING,
  UPDATE_VIEWPORT,
  UPDATE_LEGENDS,
  UPDATE_MAP_HAS_BEEN_LOADED,
  UPDATE_IS_GET_IN_TOUCH,
} from "../store";
import { MAPBOX_KEY } from "../settings";
import { legendColors, legendColorsString, randomColor } from "../colors";
import api from "../api";
import countries from "../countries";

import LinearProgressWithLabel from "./LinearProgressWithLabel";
import Legends from "./Legends";

import { Mail, X } from "react-feather";

const legendType = {
  categorical: "categorical",
  sequential: "sequential",
  diverging: "diverging",
  none: "none",
};
//

const TooltipContent = ({ data }) => (
  <Box>
    {data ? (
      <>
        <Text fontWeight="bold" fontSize="10px" color="gray.2" m="2px 2px 6px">
          PROPERTIES
        </Text>
        <Box flexDirection="column" m="2px">
          {Object.keys(data.properties).map((property, key) => (
            <Box flexDirection="row" key={key}>
              <Box width="50%">
                <Text
                  fontSize="12px"
                  fontWeight="bold"
                  color="gray.0"
                  style={{ marginRight: "10px" }}
                >
                  {property}
                </Text>
              </Box>
              <Box width="50%">
                <Text fontSize="12px" fontWeight="light" color="gray.0">
                  {data.properties[property]}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </>
    ) : null}
  </Box>
);
const getColor = (d, idx) => {
  /// Reduce opacity for shapes
  if (["Polygon", "MultiPolygon"].includes(d.type)) {
    return [...legendColors[idx], 178]; // opacity to 0.4
  } else {
    return [...legendColors[idx], 178]; // opacity to 0.5
  }
};
const Map = styled(({ className, tooltipLayers }) => {
  const ref = useRef(null);
  const viewport = useSelector((state) => state.viewport);
  const isMapLoading = useSelector((state) => state.isMapLoading);
  const mapLoadingProgress = useSelector((state) => state.mapLoadingProgress);
  const mapHasBeenLoaded = useSelector((state) => state.mapHasBeenLoaded);
  const data = useSelector((state) => state.mapLayers);
  const [hoveredData, onHover] = useDeckGlTooltip(Object.keys(data));
  const [layers, setLayers] = useState(null);
  const isWarning = useSelector((state) => state.isWarning);
  let legends = useSelector((state) => state.legends);
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.userName);

  useEffect(() => {
    // need to populate height and width so panning works
    if (ref && ref.current) {
      dispatch({
        type: UPDATE_VIEWPORT,
        viewport: {
          ...viewport,
          width: ref.current._containerRef.current.offsetWidth,
          height: ref.current._containerRef.current.offsetHeight,
        },
      });
      api
        .get("/country")
        .then(({ data }) => {
          console.log(`Country code ${data.country_code}`);
          const box = countries[data.country_code]
            ? countries[data.country_code]
            : countries["PH"];
          const viewportWithHeightWidth = {
            ...viewport,
            width: ref.current._containerRef.current.offsetWidth,
            height: ref.current._containerRef.current.offsetHeight,
          };

          const { longitude, latitude, zoom } = new WebMercatorViewport(
            viewportWithHeightWidth
          ).fitBounds([box.slice(0, 2), box.slice(2, 4)], { padding: 50 });
          dispatch({
            type: UPDATE_VIEWPORT,
            viewport: {
              ...viewportWithHeightWidth,
              longitude,
              latitude,
              zoom: zoom,
            },
          });
        })
        .catch(() => {
          console.log(`Could not determine country. Using PH`);
          const box = countries["PH"];
          const viewportWithHeightWidth = {
            ...viewport,
            width: ref.current._containerRef.current.offsetWidth,
            height: ref.current._containerRef.current.offsetHeight,
          };

          const { longitude, latitude, zoom } = new WebMercatorViewport(
            viewportWithHeightWidth
          ).fitBounds([box.slice(0, 2), box.slice(2, 4)], { padding: 150 });
          dispatch({
            type: UPDATE_VIEWPORT,
            viewport: {
              ...viewportWithHeightWidth,
              longitude,
              latitude,
              zoom: zoom,
            },
          });
        });
    }
    // eslint-disable-next-line
  }, [ref]);
  useEffect(() => {
    const l = Object.keys(data)
      .filter((key) => data[key].isVisible)
      .map((key, idx) => {
        // eslint-disable-next-line
        let legends;
        const {
          color_data_field: colorField,
          color_data_field_type: colorFieldType,
        } = data[key].datasetInfo;
        const layerData = data[key].data;
        if (colorField && colorFieldType) {
          if (colorFieldType === "categorical") {
            const categories = uniq(
              layerData.features.map((f) => f.properties[colorField])
            );
            const colors = randomColor({
              count: categories.length,
              seed: key,
            });
            legends = {
              ...legends,
              [key]: {
                type: legendType.categorical,
                colors,
                categories,
                field: colorField,
              },
            };
            const getColorCatagery = (d, idx) => {
              const color =
                colors[categories.indexOf(d.properties[colorField])];
              const color_array = color.match(/\d+/g).map((b) => parseInt(b));
              return [
                ...color_array,
                ["Polygon", "MultiPolygon"].includes(d.type) ? 178 : 178,
              ];
            };
            return new GeoJsonLayer({
              id: `${key}`,
              data: layerData,
              filled: true,
              lineWidthMinPixels: 1,
              pointRadiusMinPixels: 5,
              getRadius: 7,
              pickable: true,
              getFillColor: (d) => getColorCatagery(d, idx),
              getLineColor: (d) => getColorCatagery(d, idx),
            });
          } else if (colorFieldType === "sequential") {
            const primaryColor = legendColorsString[idx];

            const steps = 8;
            const maxValue = max(
              layerData.features.map((f) => f.properties[colorField])
            );
            const minValue = min(
              layerData.features.map((f) => f.properties[colorField])
            );
            const stepSize = (maxValue - minValue) / (steps - 1);
            const colors = gradstop({
              stops: steps + 1,
              inputFormat: "hex",
              colorArray: [
                lighten(0.4, primaryColor),
                primaryColor,
                darken(0.5, primaryColor),
              ],
            });

            legends = {
              ...legends,
              [key]: {
                type: legendType.sequential,
                colors,
                minValue,
                maxValue,
                field: colorField,
              },
            };
            const getSequentialColors = (d, idx) => {
              const value = Number(d.properties[colorField]);
              if (Number.isNaN(value)) {
                console.log(
                  `Found a none number in sequential field ${d.properties[colorField]}`
                );
                return [
                  [0, 0, 0],
                  ["Polygon", "MultiPolygon"].includes(d.type) ? 178 : 178,
                ];
              }

              const stepNum = floor(
                (value >= minValue ? value - minValue : 0) / stepSize
              );
              const color = colors[stepNum];
              const color_array = color.match(/\d+/g).map((b) => parseInt(b));
              return [
                ...color_array,
                ["Polygon", "MultiPolygon"].includes(d.type) ? 77 : 128,
              ];
            };
            return new GeoJsonLayer({
              id: `${key}`,
              data: layerData,
              filled: true,
              lineWidthMinPixels: 1,
              pointRadiusMinPixels: 5,
              getRadius: 7,
              pickable: true,
              getFillColor: (d) => getSequentialColors(d, idx),
              getLineColor: (d) => getColor(d, idx),
            });
          } else if (colorFieldType === "diverging") {
            const steps = 8;
            const maxValue = max(
              layerData.features.map((f) => f.properties[colorField])
            );
            const minValue = min(
              layerData.features.map((f) => f.properties[colorField])
            );
            const stepSize = (maxValue - minValue) / (steps - 1);
            const colors = gradstop({
              stops: steps + 1,
              inputFormat: "hex",
              colorArray: ["#fdae61", "#2c7bb6"],
            });
            legends = {
              ...legends,
              [key]: {
                type: legendType.diverging,
                colors,
                minValue,
                maxValue,
                field: colorField,
              },
            };
            const getDivergingColors = (d, idx) => {
              const value = Number(d.properties[colorField]);
              if (Number.isNaN(value)) {
                console.log(
                  `Found a none number in diverging field ${d.properties[colorField]}`
                );
                return [
                  [0, 0, 0],
                  ["Polygon", "MultiPolygon"].includes(d.type) ? 178 : 178,
                ];
              }
              const stepNum = floor((value - minValue) / stepSize);
              const color = colors[stepNum];
              const color_array = color.match(/\d+/g).map((b) => parseInt(b));
              return [
                ...color_array,
                ["Polygon", "MultiPolygon"].includes(d.type) ? 77 : 128,
              ];
            };
            return new GeoJsonLayer({
              id: `${key}`,
              data: layerData,
              filled: true,
              lineWidthMinPixels: 1,
              pointRadiusMinPixels: 5,
              getRadius: 7,
              pickable: true,
              getFillColor: (d) => getDivergingColors(d, idx),
              getLineColor: (d) => getDivergingColors(d, idx),
            });
          }
        } else {
          console.log(
            `Did not find valid colorDataFieldType: ${colorFieldType}`
          );
        }

        legends = {
          ...legends,
          [key]: { type: legendType.none, color: legendColorsString[idx] },
        };

        return new GeoJsonLayer({
          id: `${key}`,
          data: layerData,
          filled: true,
          lineWidthMinPixels: 1,
          pointRadiusMinPixels: 5,
          getRadius: 7,
          pickable: true,
          getFillColor: (d) => getColor(d, idx),
          getLineColor: (d) => getColor(d, idx),
        });
      });
    dispatch({ type: UPDATE_LEGENDS, legends });

    setLayers(l);
    // eslint-disable-next-line
  }, [data]);

  return (
    <DeckGL
      ref={ref}
      layers={layers}
      viewState={viewport}
      onViewStateChange={({ viewState }) =>
        dispatch({ type: UPDATE_VIEWPORT, viewport: viewState })
      }
      controller={!isWarning && !isMapLoading}
      pickingRadius={5}
      ContextProvider={MapContext.Provider}
      onHover={(info, event) => onHover(info, event)}
      minZoom={2}
      style={{ overflow: "hidden" }}
    >
      <StaticMap
        mapStyle="mapbox://styles/thinkdatasci/ckagfusvy0kmi1in2cmn5ailx"
        mapboxApiAccessToken={MAPBOX_KEY}
      />
      <Legends />
      <Tooltip
        className={className}
        closeButton={false}
        closeOnClick={false}
        hoveredData={hoveredData}
        render={(feature) => <TooltipContent data={feature} />}
        captureDrag={false}
      />
      <div
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          zIndex: 1,
        }}
      >
        <NavigationControl />
      </div>
      {isWarning ? (
        <Box
          bg="gray.1"
          zIndex="500"
          opacity=".9"
          width="300px"
          height="200px"
          borderRadius="5px"
          style={{
            position: "relative",
            margin: "auto",
            top: "30%",
          }}
        >
          <CloseIcon
            style={{
              color: "white",
              marginRight: "0px",
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={() =>
              dispatch({ type: UPDATE_ISWARNING, isWarning: false })
            }
          />
          <ErrorIcon style={{ fontSize: 80, color: "white", margin: "auto" }} />
          <Text m={4} color="white" textAlign="center">
            Please Contact Us to Download this Data Set
          </Text>
        </Box>
      ) : null}

      <Box justifyContent="flex-end" zIndex="1000" height="100%" width="100%">
        {isMapLoading ? (
          <Box alignSelf="center" height="200px" borderRadius="5px">
            <LinearProgressWithLabel value={mapLoadingProgress} />
          </Box>
        ) : (
          <Box
            alignSelf="center"
            opacity=".9"
            width="400px"
            height="200px"
            borderRadius="5px"
            style={{
              visibility: "hidden",
            }}
          >
            <LinearProgressWithLabel value={mapLoadingProgress} />
          </Box>
        )}

        {mapHasBeenLoaded === 1 ? (
          <Box
            width="360px"
            flexDirection="column"
            backgroundColor="white"
            px={5}
            py={5}
            ml={5}
            mb={6}
          >
            <Box
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                width="60px"
                height="60px"
                borderRadius="100px"
                backgroundColor="rgba(82, 103, 180, 0.1)"
              >
                <Mail color="#5268B4"></Mail>
              </Box>

              <Box
                color="#ACAAAB"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch({
                    type: UPDATE_MAP_HAS_BEEN_LOADED,
                    mapHasBeenLoaded: 0,
                  });
                }}
              >
                <X></X>
              </Box>
            </Box>

            <Box mt={3}>
              <H5>
                Hi {userName ? userName.split(" ")[0] : null}! Like what you
                see?
              </H5>
            </Box>

            <Paragraph mt={2} fontSize="14px">
              If you want to see more, let’s get in touch and we’ll schedule a
              demo with you.
            </Paragraph>

            <Box mt={3} flexDirection="row" alignItems="center">
              <Button
                variant="secondary"
                buttonColor="accent.3"
                onClick={() => {
                  let inviteCount = mapHasBeenLoaded;
                  inviteCount++;
                  dispatch({
                    type: UPDATE_MAP_HAS_BEEN_LOADED,
                    mapHasBeenLoaded: inviteCount,
                  });
                }}
              >
                NOT NOW
              </Button>

              <Button
                ml={2}
                variant="primary"
                buttonColor="accent.3"
                onClick={() => {
                  dispatch({
                    type: UPDATE_IS_GET_IN_TOUCH,
                    isGetInTouch: true,
                  });
                }}
              >
                GET IN TOUCH
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            width="18vw"
            flexDirection="column"
            backgroundColor="white"
            style={{
              visibility: "hidden",
            }}
            px={5}
            py={5}
            ml={5}
            mb={6}
          >
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="60px"
              height="60px"
              borderRadius="100px"
              backgroundColor="rgba(82, 103, 180, 0.1)"
            >
              <Mail color="#5268B4"></Mail>
            </Box>

            <Box mt={3}>
              <H5>
                Hi {userName ? userName.split(" ")[0] : null} Like what you see?
              </H5>
            </Box>

            <Paragraph mt={2} fontSize="14px">
              If you want to see more, let’s get in touch and we’ll schedule a
              demo with you.
            </Paragraph>

            <Box mt={3} flexDirection="row" alignItems="center">
              <Button
                variant="secondary"
                buttonColor="accent.3"
                onClick={() => {
                  dispatch({
                    type: UPDATE_MAP_HAS_BEEN_LOADED,
                    mapHasBeenLoaded: false,
                  });
                }}
              >
                NOT NOW
              </Button>

              <Button
                ml={2}
                variant="primary"
                buttonColor="accent.3"
                onClick={() => {
                  dispatch({
                    type: UPDATE_IS_GET_IN_TOUCH,
                    isGetInTouch: true,
                  });
                }}
              >
                GET IN TOUCH
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </DeckGL>
  );
})`
  .mapboxgl-popup-content {
    padding: 8px;
    bottom: 10px;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
`;

export default Map;
